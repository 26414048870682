<template>
  <v-container fluid>
    <v-row
      align-content="center"
      justify="center"
      class="text-center"
      :style="style_nasty"
    >
      <h1>
        Well ... <br>
        I think you are doing something nasty right ?
      </h1>
    </v-row>

    <v-row
      align="center"
      justify="center"
    >
      <video
        playsinline
        autoplay
        muted
        loop
        :height="this.$store.state.windowH/2"
      >
        <source
          :src="require('@/assets/grenade_video.mp4')"
          type="video/mp4"
        >

      </video>
    </v-row>

    <v-row
      align-content="center"
      justify="center"
      class="text-center"
      :style="style_nasty"
    >
      <h1>
        Because IP address has changed ... <br>
        So, please don't steal someone else's session.
        <v-icon
          x-large
          :dark="this.$store.state.darkTheme ? true:false"
        >
          fas fa-smile-wink
        </v-icon>
      </h1>
    </v-row>
  </v-container>
</template>

<script>
  import config from '@/config'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,
      bg_dark: config.colors.gricad_darkblue_dark2,
    }),

    computed: {
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
          'font-size': '30px',
        }
      },
      style_nasty () {
        return {
          'font-size': '25px',
          'margin-top': '30px',
          'margin-bottom': '30px',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'background-color': this.blue,

        }
      },
    },

  }
</script>

<style lang="scss">
</style>
